<template>
  <div>
    <div class="columns is-centered is-mobile">
      <div :class="{'is-disabled': (!guild.isManager || guild.guildDB.data.twitch.length >= (guild.guildDB.data.premium ? 10 : 6) )}" class="column is-full has-text-centered">
        <b-button expanded type="is-info" :disabled="twitchAdding" @click="showTwitchAdd = !showTwitchAdd">Add Streamer ({{ guild.guildDB.data.twitch.length }}/{{ guild.guildDB.data.premium ? 10 : 6 }})</b-button>
      </div>
    </div>
    <AddTwitch v-if="showTwitchAdd || twitchAdding" :guildData="guild ? guild : null" />
    <div class="columns is-centered" style="padding-top: 0.5rem !important;">
      <div class="column is-centered">
        <h3 v-if="guild.guildDB.data.twitch.length <= 0" class="title is-3" style="text-align: center;">Add a streamer to get notified!</h3>
        <div v-else class="columns is-multiline" v-for="(chunk, index) in this.chunkArray(guild.guildDB.data.twitch, 3)" :key="index">
          <div class="column is-4" v-for="streamer in chunk" :key="streamer.streamID">
            <div class="card has-shadow twitch-card">
              <button class="delete twitch-delete" @click="deleteStreamer($event, streamer.id, streamer)"></button>
              <div :class="{'is-disabled': !guild.isManager}" class="card-content" style="height: 100%;">
                <div class="media">
                  <div class="media-content" style="overflow-x: unset;">
                    <router-link tag="p" :to="{ path: '/redirect/?to=' + `https://www.twitch.tv/${streamer.streamName}` }" class="title is-4 twitch-streamer-name" :class="{'mainTextColor': streamer.status}">{{ streamer.streamDisplayName }}</router-link>
                    <p class="is-6 has-text-grey-light">Status: <span v-if="streamer.status" class="mainTextColor has-text-weight-bold">LIVE</span><span v-else>Offline</span></p>
                    <p class="is-6 has-text-grey-light">Channel: {{ (guild.bot.data.channels.find(g => g.id === streamer.aChannel) ? '#' + guild.bot.data.channels.find(g => g.id === streamer.aChannel).name : 'Unknown') }}</p>
                    <p class="is-6 has-text-grey-light">Updated: {{ $MomentJS((streamer.lastUpdated * 1000)).fromNow() }}</p>
                    <p class="is-6 has-text-grey-light">Sending By: {{ streamer.viaWebhook ? 'Webhook' : 'Grace' }}</p>
                  </div>
                  <div class="media-right">
                    <figure class="image">
                      <router-link tag="img" :to="{ path: '/redirect/?to=' + `https://www.twitch.tv/${streamer.streamName}` }" :class="{'pulseAnimationRED': streamer.status}" :src="`${streamer.avatarURL}`" class="to-twitch" :onerror="'this.src=\'https://cdn.gracebot.net/twitch/GlitchPurple.png\';'" :alt="streamer.streamDisplayName"> </router-link>
                    </figure>
                  </div>
                </div>
                <div class="content" style="flex: auto;">
                  <p class="title is-6" style="margin-bottom: 10px;">Notified Roles:</p>
                  <div v-if="guild.bot.data.roles.find(g => g.id === streamer.aTagRole) && streamer.aTagRole !== 'everyone'">
                    <div class="columns is-multiline is-centered" v-for="(chunkLevel, index) in chunkArray([...guild.bot.data.roles.find(g => g.id === streamer.aTagRole)], 2)" :key="index">
                      <div class="column is-centered" v-for="(level, index) in chunkLevel" :key="level.id + index" :style="`visibility: ${level.sameas1 ? 'hidden': 'visible'};`" style="width: max-content; padding-bottom: 0px;">
                        <p class="has-text-grey-light" :style="`border-color: #${level.color.toString(16).padStart(6, '0')};`" style="border: 1.2px solid; width:100%; border-radius: 15px;padding: 0px 20px;font-size: 14px;text-align: center;">
                          {{ level.name }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="columns is-multiline is-centered">
                      <div class="column is-centered" :style="`visibility: visible`" style="width: max-content; padding-bottom: 0px;">
                        <p class="has-text-grey-light" :style="`border-color: lightgrey};`" style="border: 1.2px solid; width:100%; border-radius: 15px;padding: 0px 20px;font-size: 14px;text-align: center;">
                          @everyone
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import 'vue-select/dist/vue-select.css'
  // import JsColor from '@/components/JsColor.vue'
  // import vSelect from 'vue-select'
  import { createPopper } from '@popperjs/core'
  import AddTwitch from '@/components/addTwitch.vue'

  export default {
    name: 'Twitch',
    components: {
      // JsColor,
      // vSelect
      AddTwitch
    },
    props: ['guildData'],
    computed: {
      guild: function () {
        return this.guildData
      }
    },
    data () {
      return {
        showTwitchAdd: false,
        twitchAdding: false,
        SelectPlacement: 'bottom',
        chunkArray: function (myArray, chunksize) {
          var index = 0
          var arrayLength = myArray.length
          var tempArray = []
          for (index = 0; index < arrayLength; index += chunksize) {
            const myChunk = myArray.slice(index, index + chunksize)
            tempArray.push(myChunk)
          }
          return tempArray
        },
        chunkArray2: function (myArray, chunksize) {
          var index = 0
          var arrayLength = myArray.length
          var tempArray = []
          for (index = 0; index < arrayLength; index += chunksize) {
            let myChunk = myArray.slice(index, index + chunksize)
            if (myChunk.length === 1) {
              const extra = { ...myArray.slice(index, index + chunksize)[0], sameas1: true }
              myChunk = [...myArray.slice(index, index + chunksize), extra]
            }
            tempArray.push(myChunk)
          }
          return tempArray
        }
      }
    },
    created () {
    },
    methods: {
      getGuild (resetT) {
        if (resetT) this.showTwitchAdd = false
        this.$parent.getGuild()
      },
      undbindELTarJquery (el) {
        this.$(el.target).unbind()
      },
      vselectfixer (dropdownList, component, { width }) {
        dropdownList.style.width = width
        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: this.SelectPlacement,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -1]
              }
            },
            {
              name: 'toggleClass',
              enabled: true,
              phase: 'write',
              fn ({ state }) {
                component.$el.classList.toggle('drop-up', state.SelectPlacement === 'top')
              }
            }
          ]
        })
        return () => popper.destroy()
      },
      deleteStreamer (el, id, streamer) {
        el = this.$(el.path[2]).addClass('is-disabled')
        this.$store.dispatch('deleteApi', { path: `guild/${this.$route.params.id}/twitch/${streamer.streamID}`, body: { id: id, streamer: streamer.streamID }, headers: {}, params: {} }).then(async (result) => {
          this.$buefy.snackbar.open({
            message: streamer.streamName + ' Deleted!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          this.guild.guildDB.data.twitch = this.guild.guildDB.data.twitch.filter(g => g.id !== id)
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        }).catch(async (error) => {
          console.log(error, 'Error')
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          el.removeClass('is-disabled')
          await this.$store.dispatch('sleep', 2000)
          this.getGuild()
        })
      }
    }
  }
</script>

<style>
  .vs--disabled .vs__dropdown-toggle, .vs--disabled .vs__clear, .vs--disabled .vs__search, .vs--disabled .vs__selected, .vs--disabled .vs__open-indicator {
    background: var(--main-color-bg-2);
    cursor: not-allowed;
    color: #7a7a7a !important;
  }
  .vs__dropdown-toggle {
    background: var(--main-color-bg-2);
    color: white !important;
    border-color: var(--main-color-bg-3) !important;
    min-height: 2.25em !important;
    height: 100% !important;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3) !important;
  }
  .vs__dropdown-menu {
    background: var(--main-color-bg-1);
    color: white;
    border-color: var(--main-color-bg-3) !important;
    border-top-style: unset !important;
    border-radius: 4px 4px 4px 4px !important;
  }
  .vs__open-indicator {
    fill: #6045aa !important;
  }
  .vs__open-indicator:hover {
    fill: #7957d5 !important;
  }
  .vs__clear {
    fill: darkred !important;
  }
  .vs__clear:hover {
    fill: red !important;
  }
  .vs--single .vs__selected {
    color: white;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
  }
  .vs__dropdown-option--selected {
    background-color: var(--main-color-bg-2);
  }
  .vs__dropdown-option--highlight {
    background: var(--main-color-bg-3) !important;
  }
  .vs_actions {
    cursor: pointer;
  }
  .vs__selected {
    background-color: var(--main-color-bg-1);
    color: white;
  }
  .vs__deselect {
    fill: darkred;
  }
  .vs__deselect:hover {
    fill: red;
  }
</style>

<style>
  .labelcolor-warning > .label {
    color: #ffdd57 !important
  }
  .help {
    color: white !important;
  }
  .hovermessagefeild:hover > .help {
    display: unset !important;
  }
  .hovermessagefeild > .help {
    display: none !important;
  }
  .is-invisible {
    display: none !important;
  }
  .has-shadow {
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important
  }
</style>

<style lang="scss" scoped>

@media only screen and (max-width: 768px) {
  .media-right {
    display: none;
  }
}

.twitch-card {
  height: 100% !important;
  border-radius: 10px !important;
  background: var(--main-color-bg-2) !important;
}

.twitch-delete {
  float: right;
  margin-top: 10px;
  margin-right: 10px;
  visibility: hidden;
}

.twitch-card:hover .twitch-delete {
  visibility: visible !important;
}

.modal-close:hover::before, .delete:hover::before, .modal-close:hover::after, .delete:hover::after {
  background-color: #f04747 !important;
}

.to-twitch {
  cursor: pointer;
  transition: .3s;
  width: 80px;
  height: 80px;
  object-fit: contain;
  border-radius: 100px;
  border-color: var(--main-color-bg-3);
  border-width: 4px;
  border-radius: 100px;
  border-style: solid;
}

.to-twitch:hover {
  filter: brightness(.6);
  -webkit-filter: brightness(.6);
  transform: scale(1.02);
  transition: all .3s ease-in-out;
}

.twitch-streamer-name {
  margin-bottom: 0px;
  cursor:pointer;
  transition: .3s;
  width: fit-content;
}

.twitch-streamer-name:hover {
  color: #7957d5 !important;
}

.is-6.has-text-grey-light {
  width: fit-content;
}

.button.is-fullwidth {
  box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important;
}
.columns {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
#sectionPageMain {
  padding: 0.5rem 1.5rem !important;
  padding-bottom: 3rem !important;
}
.card {
  border-radius: 6px;
  border: 1px solid var(--main-color-bg-3);
  background: var(--main-color-bg);
  height: unset !important;
}
h3.subtitle.is-spaced {
  color: lightgray;
}
.p-1 {
  padding: 1em;
}
.sidebar-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    /* min-height: 100vh; */
    .sidebar-layout {
        display: flex;
        flex-direction: row;
        min-height: 100%;
        min-height: calc(100vh - ( 3.25rem - 0rem ) );
    }
}
</style>
